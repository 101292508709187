import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/analytics';
import { setUserId } from 'firebase/analytics';
import { GoogleAuthProvider, signInWithCredential } from 'firebase/auth';
import { OAuthProvider } from 'firebase/auth';

const liveFirebaseConfig = {
  apiKey: 'AIzaSyBk9_REyjSphtnApRLO9gKhPM7M9ToM4SA',
  authDomain: 'g3ms-prod.firebaseapp.com',
  projectId: 'g3ms-prod',
  storageBucket: 'g3ms-prod.firebasestorage.app',
  messagingSenderId: '1041797166744',
  appId: '1:1041797166744:web:47182d94385423ed0742ed',
  measurementId: 'G-5GGDCX19XJ',
};

const devFirebaseConfig = {
  apiKey: "AIzaSyCzR0-ikOGy8G9rOoP8GFCckpNdz_D7PmQ",
  authDomain: "g3ms-dev.firebaseapp.com",
  projectId: "g3ms-dev",
  storageBucket: "g3ms-dev.appspot.com",
  messagingSenderId: "881122938032",
  appId: "1:881122938032:web:bc8bbc07f1216fdcb27f75",
  measurementId: "G-EVYV2Y4JQD"
}

// Initialize Firebase
const app = firebase.initializeApp(liveFirebaseConfig);

// init collections
const db = firebase.firestore();
export const usersCollection = db.collection('users');

// init auth
export const auth = firebase.auth();

export const googleProvider = new GoogleAuthProvider();
export const appleProvider = new OAuthProvider('apple.com');
export const classLinkProvider = new OAuthProvider('oidc.classlink');

export const cleverProvider = new OAuthProvider('oidc.clever');
googleProvider.setCustomParameters({ prompt: 'select_account' });
classLinkProvider.setCustomParameters({
  // Target specific email with login hint.
  prompt: 'login',
});

export const analytics = firebase.analytics();
export const setAnalyticsUserId = setUserId;

export const signInWithId = signInWithCredential;
